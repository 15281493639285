import React from 'react'
import './MisionVision.scss'
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const MisionVision = ({mision, vision}) => {

  return (
    <section className='mision-vision'>
      <div className='mision-vision__mision'>
        {documentToReactComponents(JSON.parse(mision.raw))}

      </div>
      <div className='mision-vision__vision'>
        {documentToReactComponents(JSON.parse(vision.raw))}

      </div>

    </section>
  )
}

export default MisionVision

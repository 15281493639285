import React from 'react'
import Banner from "../Banner/Banner";
import Clients from "../../molecules/Clients/Clients";
import MisionVision from "../../molecules/MisionVision/MisionVision";
import Contact from "../Contact/Contact";
import {graphql, useStaticQuery} from "gatsby";

const ContentOur = () => {



  const { allContentfulSeccionNosotros } = useStaticQuery(
    graphql`
        query Our_Query {
            allContentfulSeccionNosotros {
                nodes {
                    descripcionPrincipal {
                        raw
                    }
                    mision {
                        raw
                    }
                    vision {
                        raw
                    }
                    banner {
                        file {
                            url
                        }
                        gatsbyImageData
                        description
                    }
                }
            }
        }


    `
  )

  const contentInfo = allContentfulSeccionNosotros.nodes[0]


  return (
    <>
      <Banner
        description={contentInfo.descripcionPrincipal}
        image={contentInfo.banner}
      />
      <MisionVision mision={contentInfo.mision} vision={contentInfo.vision}/>
      <Clients/>
      <Contact/>

    </>
  )
}

export default ContentOur

import React from 'react'
import Layout from "../components/organisms/layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentOur from "../components/organisms/ContentOur/ContentOur";
const Nosotros = () => {
  return (
    <Layout>
      <SEO
        title='Nosotros'
        description='Cold Chain Logistics'
      />
      <ContentOur />
    </Layout>
  )
}

export default Nosotros
